import React from 'react';
import classNames from 'classnames';

// styles
import './button.scss';

const Button = ({ text, icon, disabled = false, type, onClick, className }) => {
  if (!(text || icon)) {
    return null;
  }
  const classes = Array.isArray(className) ? [...className] : className;

  return (
    <button
      type={type}
      className={classNames('btn-primary', classes, {
        'custom-btn--disabled': disabled,
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {icon ? <i className={classNames('icon', 'fas', icon)}></i> : null}
      {text ? <span>{text}</span> : null}
    </button>
  );
};

export default Button;
