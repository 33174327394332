import React, { Component } from 'react';
import Button from '../../atoms/Button/button';

// Styles
import './404.scss';

class Error extends Component {
  render() {
    return window !== 'undefined' ? (
      <div className="container-404">
        <div className="card-panel">
          <h1>OOPS.</h1>
          <p>
            Looks like the page you are trying to reach is no longer available.
            We're sorry for the inconvenience.
          </p>
          <Button icon="fa-home" className="btn-primary" text="Take Me Home" />
        </div>
      </div>
    ) : null;
  }
}

export default Error;
