import React, { useEffect } from 'react';
import Error from '../components/scenes/404/404';

export default () => {
  let shouldRender = false;
  useEffect(() => {
    if (window) {
      shouldRender = true;
    }
  }, []);

  return shouldRender ? <Error /> : null;
};
